import React from 'react';
import { NavLink } from 'react-router-dom';

import groupPic from "../images/Picture4.jpg";
import rakel from "../images/portrett-rakel-kraft.jpg";
import jannike from "../images/portrett-jannike-hansen.jpg";
import henry from "../images/portrett-henri-teigen-markhus.jpg";

const aboutList = [
    {
        title: 'Rakel Kraft',
        description: 'Daglig leder',
        imageSrc: rakel,
        link: "#",
    },
    {
        title: 'Jannike Hansen',
        description: "Daglig leder",
        imageSrc: jannike,
        link: "#",
    },
    {
        title: 'Henri Teigen Markhus',
        description: "Økonomi ansvarlig",
        imageSrc: henry,
        link: "#",
    }    
];

function about() {
  return (
    <main>
        <header className='aboutImg'>
          <img src={groupPic} alt='Pop-Up Ub'></img>
        </header>
            <div className='aboutList-wrapper'>
                {aboutList.map((who, index) => (
                    <div key={index} className='aboutList-card'>
                        <NavLink to={who.link} className="aboutList-inside">
                            <img src={who.imageSrc} alt={who.title} />
                            <p style={{ fontWeight: 'bold' }}>{who.title}</p>
                            <p>{who.description}</p>
                        </NavLink>
                    </div>
                ))}
            </div>
            
    </main>
  )
}

export default about;
