import React, { useState, useEffect } from 'react';
import SlideButton from './slide-button';

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('isDarkMode') === 'true');

  useEffect(() => {
      localStorage.setItem('isDarkMode', isDarkMode);
      const root = document.documentElement;
      if (isDarkMode) {
          root.classList.add('dark-mode');
      } else {
          root.classList.remove('dark-mode');
      }
    }, [isDarkMode]);

  const toggleDarkMode = () => {
      setIsDarkMode(prevIsDarkMode => !prevIsDarkMode);
    };

    return (
      <span onClick={toggleDarkMode}>
          <SlideButton isDarkMode={isDarkMode}/>
      </span>
    );
};

  export default DarkModeToggle;