import React, { useState, useEffect } from 'react';
import { CiLight } from "react-icons/ci";

function SlideButton() {
    const [dotPosition, setDotPosition] = useState(localStorage.getItem('dotPosition') || '1px');
    const dotSize = '24px';

    useEffect(() => {
        localStorage.setItem('dotPosition', dotPosition);
    }, [dotPosition]);

    const moveDot = () => {
    const newDotPosition = dotPosition === '1px' ? `calc(100% - ${dotSize} - 1px)` : '1px';
    setDotPosition(newDotPosition);
    };

    const buttonColor = dotPosition === '1px' ? '#eeeeee' : '#333';
    const dotColor = dotPosition === '1px' ? '#333' : '#eeeeee';

    return (
        <div onClick={moveDot}>
            <button
                className='slideButton'
                style={{
                position: 'relative',
                width: '50px',
                height: '25px',
                borderRadius: '30px',
                backgroundColor: buttonColor,
                border: 'none',
                cursor: 'pointer',
                overflow: 'hidden',
                boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.3)',
                }}
            >
                <span
                className='dot'
                style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: dotPosition,
                    width: dotSize,
                    height: dotSize,
                    borderRadius: '50%',
                    backgroundColor: dotColor,
                    transition: 'left 0.3s ease',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <CiLight size='16' color='var(--white)' />
                </span>
            </button>
        </div>
  );
}

export default SlideButton;
