import React from "react";

const styles = {
    youtubeClip: {
        position: "relative",
        width: "100%",
        height: "0",
        paddingBottom: "56.25%",
        justifyContent: "center",
        borderRadius: "10px"
      },
    youtubeIframe: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            border: "none",
          }
      };

export default function YoutubeClip() {
  return (
    <div style={styles.youtubeClip}>
      <iframe
        style={styles.youtubeIframe}
        width="560" 
        height="315"
        src="https://www.youtube.com/embed/mclEpJevHQw?si=U7fT0Otr9kETPscF" 
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen></iframe>
    </div>
  )
}
