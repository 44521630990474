import React from 'react';
import './layout.css';

function Footer() {
  return (
    <footer>
      <div className='content'>
        <p>&copy; {new Date().getFullYear()} Pop-Up UB</p>
      </div>
    </footer>
  )
}

export default Footer;