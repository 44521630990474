import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiBars3, HiOutlineXMark } from "react-icons/hi2";
import logo from "../images/logoS.png";
import DarkModeToggle from '../components/darkMode';
import "./layout.css";

function Navbar() {
    const [showNavbar, setShowNavbar] = useState(false);

    const toggleNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    const closeNavbar = () => {
        setShowNavbar(false);
    };
    
    return (
        <nav className="navbar">
            <div className="nav-container">
            <NavLink to="/" className="nav-logo">
                <img src={logo} alt='Logo' />
            </NavLink>
                <div className={`nav-menu ${showNavbar ? 'active' : ''}`}>
                    <div className='nav-menu-wrapper'>
                        <ul onClick={closeNavbar}>
                            <li>
                                <NavLink
                                to="/"
                                activeclassname="active"
                                >
                                Pop-Up UB
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                to="/Om_oss"
                                activeclassname="active"
                                >
                                Om oss
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                to="/Blogg"
                                activeclassname="active"
                                >
                                Blogg
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                to="/Kontakt"
                                activeclassname="active"
                                >
                                Kontakt
                                </NavLink>
                            </li>
                        </ul>
                        <div className="darkModeDropdown" >
                            {showNavbar && <DarkModeToggle alt="Theme changer" />}
                        </div>
                    </div>
                </div>
                <div className="darkMode">
                    {!showNavbar && <DarkModeToggle alt="Theme changer" />}
                </div>
                <div className="hamburger" onClick={toggleNavbar}
                        style={{ 
                            height:'2rem', 
                            width:'2rem'}}
                            >
                        {showNavbar ? <HiOutlineXMark style={{ position: "fixed", height:'2rem', width: '2rem' }} /> : <HiBars3 style={{ height:'2rem', width: '2rem' }} />}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
