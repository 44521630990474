import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./layout/navbar.js";
import Footer from "./layout/footer.js";
import Home from "./pages/home.js";
import About from "./pages/about.js";
import Blog from "./pages/blog.js";
import Contact from "./pages/contact.js";
import "./global.css";


function Content() {

  return (
    <>
    <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Om_oss" element={<About />} />
        <Route path="/Blogg" element={<Blog />} />
        <Route path="/Kontakt" element={<Contact />} />
      </Routes>
    <Footer />
    </>
  );
}

export default function App() {

  return (
    <Router>
        <Content />
    </Router>
  )
}