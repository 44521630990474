import React from 'react';
import { FaFacebook, FaInstagram  } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";


export default function Socials() {
  return (
    <div className='socials'>
        <a href="mailto:popupubkrokeidevgs@gmail.com">
        <IoIosMail />
      </a>
      <a href="https://www.facebook.com/profile.php?id=61551218874030">
        <FaFacebook />
      </a>
      <a href="https://www.instagram.com/popupub_krokeidevgs?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
        <FaInstagram />
      </a>
    </div>
  )
}