import React from 'react';
import Socials from '../components/socials';
import map from "../images/map.png"

function contact() {
  return (
    <main>
      <header>
        <h2 style={{ color: "var(--col_1)" }}>Kontakt informasjon</h2>
      </header>
        <div className='contact'>
          <div>
              <ul>
                <li>
                  <h4>Adresse:</h4>                
                </li>
                <li>
                  <p>Korsnesvegen 70</p>                
                </li>
                <li>
                  <p>5244 Fana</p>
                </li>
              </ul>
          </div>
          <span className='socials'>
            <Socials />
          </span>
        </div>
        <div className='largeImg' style={{ width: "100%" }}>
          <img src={map} alt='Google map point'></img>
        </div>
    </main>
  )
}

export default contact;
