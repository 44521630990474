import React from 'react';
import { NavLink } from 'react-router-dom';

const blogList = [
  {
      date: '12. januar 2024',
      title: "Muffinday!",
      description: 'I dag solgte vi Linnéa sine vinterdrøm muffins!',
      imageSrc: require("../images/blog-img-6.jpg"),
      link: "#",
  },
  {
      date: '15. desember 2023',
      title: "Quizzz",
      description: "I dag hadde vi quiz i sentral rommet (med premie)",
      imageSrc: require("../images/blog-img-5.jpg"),
      link: "#",
  },
  {
      date: '22. november 2023',
      title: "Utdanningsmessen",
      description: "I dag var vi i Nordhordalands hallen på utdanningsmessen.",
      imageSrc: require("../images/blog-img-4.jpg"),
      link: "#",
  },
  {
      date: '10. november 2023',
      title: "Besøksdag",
      description: "I dag fikk vi besøk av lederene for ungt entrepernøskap vestland. Vi viste de rundt i bruktbutikken, og i matvognen vår. Vi ses snart på fylkesmesterskapet!",
      imageSrc: require("../images/blog-img-3.jpg"),
      link: "#",
  },
  {
      date: '15. september 2023',
      title: "Åpningsdag!",
      description: "Vi åpnet Pop-UP UB med et brak!",
      imageSrc: require("../images/blog-img-1.jpg"),
      link: "#",
  }
];

function blog() {
  return (
    <main>
      <header>
        <h2 style={{ color: "var(--col_1)" }}>Utforsk bloggen vår!</h2>
      </header>
      <section>
        <h3>Her kan du se hvor vi har vært tidligere, og hva vi har holdt på med!</h3>
      </section>
      <div className='blogList-wrapper'>
              {blogList.map((blog, index) => (
                <div key={index} className='blogList-card'>
                    <NavLink to={blog.link} className="blogList-inside">
                      <div style={{ flexGrow: '1' }}>
                        <p style={{ fontWeight: 'bold'}}>{blog.date}</p>
                        <h3 style={{ color: 'var(--col_1)' }}>{blog.title}</h3>
                        <p>{blog.description}</p>
                      </div>
                        <div>
                          <img src={blog.imageSrc} alt={blog.title} />
                        </div>
                    </NavLink>
                </div>
              ))}
        </div>
    </main>
  )
}

export default blog;