import React from 'react';
import YoutubeClip from '../components/youtubeClip';
import image1 from '../images/gallery1.webp';
import image2 from '../images/gallery2.webp';
import image3 from '../images/gallery3.webp';
import image4 from '../images/gallery4.webp';
import image5 from '../images/gallery5.webp';
import image6 from '../images/gallery6.webp';

const gallery = [
  image1,
  image3,
  image2,
  image4,
  image5,
  image6
];

function home() {
  return (
    <main>
        <header>
          <h1 style={{ color: "var(--col_1)" }}>Velkommen til Pop-Up UB!</h1>
        </header>
        <section className='homeSection-txt'>
              <h2>Ungdomsbedrift fra Krokeide videregående skole</h2>
              <br />
              <div>
                <div>
                <p>Vi er en bedrift som dukker opp på forskjellige steder og tidspunkter. Noen ganger vil vi selge klær og tilbehør, andre andre ganger vil vi tilby deilige matopplevelser. Pop-Up UB bringer mote og mat direkte til kundene.</p>
                </div>
              </div>
              <br />
              <h4>Siden er fortsatt under konstruksjon 😎</h4>
        </section>
          <div className='youtubeClip'>
            <YoutubeClip />
          </div>
          <h1 style={{ color: "var(--col_1)" }}>Fotogalleri</h1>
          <section>
            <ul className='image-gallery'>
              {gallery.map((imageSrc, index) => (
              <li key={index} >
                <img src={imageSrc} alt={`Gallery ${index}`} />
              </li>
            ))}
            </ul>
          </section>
    </main>
  )
}

export default home;
